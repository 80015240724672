import {
  Form,
  Input,
  Checkbox,
  Link,
  Button,
  Space,
  Message
} from '@arco-design/web-react';
import { FormInstance } from '@arco-design/web-react/es/Form';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import useStorage from '@/utils/useStorage';
import useLocale from '@/utils/useLocale';
import locale from './locale';
import styles from './style/index.module.less';
import instance from '@/api/instance';

export default function LoginForm() {
  const formRef = useRef<FormInstance>();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginParams, setLoginParams, removeLoginParams] =
    useStorage('loginParams');

  const t = useLocale(locale);

  const [rememberPassword, setRememberPassword] = useState(!!loginParams);

  function fetchUserInfo(token) {
    instance.get('/api/admin/getLoginAdminInfo',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':token
      }
    }).then((res) => {
    localStorage.setItem('role',res.data.data.type ? res.data.data.type : 'User')
    //localStorage.setItem('userInfomation', JSON.stringify(res.data.data));
    });
  }

  function afterLoginSuccess(params) {
    
    // 记住密码
    if (rememberPassword) {
      setLoginParams(JSON.stringify(params));
    } else {
      removeLoginParams();
    }
    // 记录登录状态
    localStorage.setItem('userStatus', 'login');
    // 跳转首页
    window.location.href = '/';
  }



  function login(params) {
    setErrorMessage('');
    setLoading(true);
    //TODO: 登录接口
    // console.log("登录",params)
    instance
      .post('/api/admin/login',params, {
         headers: {
           'Content-Type': 'application/json'
         }
      })
      .then((res) => {
       // console.log("localStorage",res.data);
       if(!res){
        //Message.error("登录失败,请重试！");
        return;
       }
        const { code, message } = res.data ;
        if (code === '0000') {
         // afterLoginSuccess(params);
          localStorage.setItem('token', res.data.data);
          fetchUserInfo(res.data.data);
          afterLoginSuccess(params);
        } else {
          setErrorMessage(message || t['login.form.login.errMsg']);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // afterLoginSuccess(params);
    // setLoading(false);
  }

  function onSubmitClick() {
    formRef.current.validate().then((values) => {
      login(values);
    });
  }

  // 读取 localStorage，设置初始值
  useEffect(() => {
    const rememberPassword = !!loginParams;
    setRememberPassword(rememberPassword);
    if (formRef.current && rememberPassword) {
      const parseParams = JSON.parse(loginParams);
      formRef.current.setFieldsValue(parseParams);
    }
  }, [loginParams]);

  return (
    <div className={styles['login-form-wrapper']}>
      <div className={styles['login-form-title']}>{'登录'}</div>
      {/* <div className={styles['login-form-sub-title']}>
        {t['login.form.title']}
      </div> */}
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <Form
        className={styles['login-form']}
        layout="vertical"
        ref={formRef}
       // initialValues={{ userName: 'admin', password: 'admin' }}
      >
        <Form.Item
          field="email"
          rules={[{ required: true, message: t['login.form.userName.errMsg'] }]}
        >
          <Input
            prefix={<IconUser />}
            placeholder={t['login.form.email.placeholder']}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Form.Item
          field="password"
          rules={[{ required: true, message: t['login.form.password.errMsg'] }]}
        >
          <Input.Password
            prefix={<IconLock/>}
            placeholder={t['login.form.password.placeholder']}
            onPressEnter={onSubmitClick}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <div className={styles['login-form-password-actions']}>
            <Checkbox checked={rememberPassword} onChange={setRememberPassword}>
              {t['login.form.rememberPassword']}
            </Checkbox>
            {/* <Link>{t['login.form.forgetPassword']}</Link> */}
          </div>
          <Button type="primary" long onClick={onSubmitClick} loading={loading}>
            {t['login.form.login']}
          </Button>
          {/* <Button
            type="text"
            long
            className={styles['login-form-register-btn']}
          >
            {t['login.form.register']}
          </Button> */}
        </Space>
      </Form>
    </div>
  );
}

import auth, { AuthParams } from '@/utils/authentication';
import { useEffect, useMemo, useState,useContext } from 'react';
import instance from './api/instance';
import useStorage from '@/utils/useStorage';
import { GlobalContext } from './context';


export type IRoute = AuthParams & {
  name: string;
  key: string;
  // 当前页是否展示面包屑
  breadcrumb?: boolean;
  children?: IRoute[];
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean;
  //path?: string;
};

export const routes: IRoute[] = [
 
  // {
  //   name: '工具支持',
  //   key: 'tools',
  // },
  {
    name: 'menu.apps',
    key: 'apps',
    children: [
      {
        name: '应用列表',
        key: 'apps/app-list',
      },
      {
        name: 'agent配置',
        key: 'apps/agent',
        ignore: true, //不显示在菜单中，但可通过路由地址访问。
      },
      {
        name: 'agent/插件编辑',
        key: 'apps/agent-edit',
        ignore: true,
      },
    ],
  },
  {
    name: '知识库',
    key: 'knowledge-base',
    ignore: true, //不显示在菜单中，但可通过路由地址访问。
    children: [
      {
        name: '文件处理',
        key: 'knowledge-base/file-upload',
        ignore: true,
      },
      {
        name: '文件处理',
        key: 'knowledge-base/json-file',
        ignore: true,
      },
      {
        name: '文件处理',
        key: 'knowledge-base/pro-qa',
        ignore: true,
      }
    ]
  },
 {
    name: '系统管理',
    key: 'system-man',
    children: [
      {
        name: '人员管理',
        key: 'system-man/staff-man',
        requiredPermissions: [
          { resource: '人员管理', actions: ['write']},
        ]
      },
      {
        name: '模型设置',
        key: 'system-man/model-man',
        requiredPermissions: [
          { resource: '模型设置', actions: ['write']},
        ]
      },
    ]
  },


  {
    name: 'API文档',
    key: 'api-doc',
    ignore: true, //不显示在菜单中，但可通过路由地址访问。
  },
  // {
  //   name: 'menu.apps',
  //   key: 'apps',
  //   children: [
  //     {
  //       name: 'menu.apps.list',
  //       key: 'apps/app-list',
  //     },
  //   ],
  // },
  // {
  //   name: 'menu.visualization',
  //   key: 'visualization',
  //   children: [
  //     {
  //       name: 'menu.visualization.dataAnalysis',
  //       key: 'visualization/data-analysis',
  //       requiredPermissions: [
  //         { resource: 'menu.visualization.dataAnalysis', actions: ['read'] },
  //       ],
  //     },
  //     {
  //       name: 'menu.visualization.multiDimensionDataAnalysis',
  //       key: 'visualization/multi-dimension-data-analysis',
  //       requiredPermissions: [
  //         {
  //           resource: 'menu.visualization.dataAnalysis',
  //           actions: ['read', 'write'],
  //         },
  //         {
  //           resource: 'menu.visualization.multiDimensionDataAnalysis',
  //           actions: ['write'],
  //         },
  //       ],
  //       oneOfPerm: true,
  //     },
  //   ],
  // },
  // {
  //   name: 'menu.list',
  //   key: 'list',
  //   children: [
  //     {
  //       name: 'menu.list.searchTable',
  //       key: 'list/search-table',
  //     },
  //     {
  //       name: 'menu.list.cardList',
  //       key: 'list/card',
  //     },
  //   ],
  // },
  // {
  //   name: 'menu.form',
  //   key: 'form',
  //   children: [
  //     {
  //       name: 'menu.form.group',
  //       key: 'form/group',
  //       requiredPermissions: [
  //         { resource: 'menu.form.group', actions: ['read', 'write'] },
  //       ],
  //     },
  //     {
  //       name: 'menu.form.step',
  //       key: 'form/step',
  //       requiredPermissions: [
  //         { resource: 'menu.form.step', actions: ['read'] },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: 'menu.profile',
  //   key: 'profile',
  //   children: [
  //     {
  //       name: 'menu.profile.basic',
  //       key: 'profile/basic',
  //     },
  //   ],
  // },

];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

export const generatePermission = (role: string) => {
  const actions = role === 'Admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

//TODO:动态路由
//const useRoute = (role:string): [IRoute[], string] => {
//const useRoute = (userPermission: any): [IRoute[], string] => {


  const useRoute = (): [IRoute[], string] => {

  console.log('角色', localStorage.getItem('role'));
  const userPermission =generatePermission(localStorage.getItem('role'));
  console.log('userPermission列表', userPermission);

  const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) {
      return [];
    }
    // 遍历传入的 routes 数组
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route; // 从路由中解构出必要的权限
      let visible = true;// 初始化可见性为 true
      if (requiredPermissions) {// 如果存在 requiredPermissions，执行权限验证
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);// 调用 auth 函数检查权限
      }

      if (!visible) {  // 如果路由不可见，跳过该路由
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] }; // 复制当前路由并初始化子路由为空数组
        filterRoute(route.children, newRoute.children); // 递归调用 filterRoute 函数，筛选子路由，并将结果赋给 newRoute.children
        if (newRoute.children.length) { // 将新路由添加到结果数组
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });  // 如果路由没有子路由，直接将当前路由添加到结果数组
      }
    }

    return arr;
  };

  const newfilterRoute = filterRoute(routes);
  console.log('过滤后newfilterRoute列表', newfilterRoute);
  const [permissionRoute, setPermissionRoute] = useState(newfilterRoute);
  console.log('初次permissionRoute列表', permissionRoute);
  const [loading, setLoading] = useState(true);
  //获取应用列表
  // const addAppsToRoutes = async () => {
  //   const res = await instance.get('/api/app/list');
    
  //   const newRoutes = [...permissionRoute]; // 使用当前的permissionRoute
  //   res.data.data.forEach((app) => {
  //     const newChildRoute: IRoute = {
  //       name: app.name,
  //       key: `apps/agent?appId=${app.appId}`,
  //     };
  //     addDataToRoutes(newChildRoute, newRoutes); // 将新的子路由添加到新路由数组中
  //   });

  //   setPermissionRoute(newRoutes); // 更新permissionRoute状态
  //   setLoading(false); // 请求完成，设置loading为false
  // };


  // 获取应用列表
  const addAppsToRoutes = async () => {
    const res = await instance.get('/api/app/list');
    
    const newRoutes = [...newfilterRoute]; // 使用当前的permissionRoute newfilterRoute
    console.log('newRoutes列表', newRoutes);
    await Promise.all(res.data.data.map(async (app) => {
      const newChildRoute: IRoute = {
        name: app.name,
        key: `apps/agent?appId=${app.appId}`,
        children: [], // 初始化子路由数组
      };
      
      // 调用接口获取子路由
      const agentRes = await instance.post('/api/agent/list',{
        appId: app.appId, 
      });
      agentRes.data.data.forEach((node) => {
        const childRoute: IRoute = {
          name: node.name,
          key: `apps/agent-edit?agentId=${node.agentId}&&appId=${app.appId}`,
          ignore: true,
        };
        newChildRoute.children.push(childRoute); // 将子路由加入到新的子路由中
      });
      addDataToRoutes(newChildRoute, newRoutes); // 将新的子路由添加到新路由数组中
    }));
    setPermissionRoute(newRoutes); // 更新permissionRoute状态
    setLoading(false); // 请求完成，设置loading为false
  };

  const addDataToRoutes = (data: IRoute, routes: IRoute[]) => {
    if (routes.length > 0 && routes[0].children) {
      routes[0].children.push(data);
    }
  };

  useEffect(() => {
    addAppsToRoutes();
    //const newRoutes = filterRoute(routes);
    //setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);
  // 添加数据到第一项的children中



  //TODO: 根据角色生成权限资源列表
  //const userPermission =generatePermission(role);
  //console.log('userPermission列表', userPermission);
  // 过滤路由
  //const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
  //  if (!routes.length) {
  //    return [];
  //  }
  //  for (const route of routes) {
  //    const { requiredPermissions, oneOfPerm } = route;
  //    let visible = true;
  //    if (requiredPermissions) {
  //      visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
  //    }

  //    if (!visible) {
  //      continue;
  //    }
  //    if (route.children && route.children.length) {
  //      const newRoute = { ...route, children: [] };
  //      filterRoute(route.children, newRoute.children);
  //      if (newRoute.children.length) {
  //        arr.push(newRoute);
  //      }
  //    } else {
  //      arr.push({ ...route });
  //    }
  //  }

  //  return arr;
  //};

  //const [permissionRoute, setPermissionRoute] = useState(routes);

  useEffect(() => {
   const newRoutes = filterRoute(permissionRoute);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);//JSON.stringify(userPermission)

  //const filter=()=>{
  //  const newRoutes = filterRoute(permissionRoute);
  //  setPermissionRoute(newRoutes);
  //}
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);
  console.log('最终permissionRoute列表', permissionRoute);

  return [permissionRoute, defaultRoute];
};

export default useRoute;

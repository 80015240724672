import React from 'react';
import { Layout, Menu, Spin,Input,Form, Button, Card, Radio,Space, List, Tag, Typography,Table, Divider,Avatar, Select, Message, InputNumber } from '@arco-design/web-react';
import { IconArrowLeft, IconSend } from '@arco-design/web-react/icon';
import instance from '@/api/instance';

const { Header, Content, Sider } = Layout;
const { TextArea } = Input;
const { Title } = Typography;

interface TestResult {
    title: string;
    content: string;
    score: number;
 }

const SearchTestPage = ({kbId}) => {

  const [form] = Form.useForm();
  const [testResults, setTestResults] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);


  const testParams = {
    question: '',
    topK: 3,
    ignoreDocStatus:true
  };

  const testHistory = [
    { key: '0', title: '语义检索 科学', date: '08/13' },
    { key: '1', title: '语义检索 历史', date: '08/13' },
  ];



  const columns = [
    {
      title: '搜索模式',
       dataIndex: 'searchPattern',
      key: 'searchPattern',
    },
    {
      title: '引用上限',
      dataIndex: 'maxLength',
      key: 'maxLength',
    },
    {
        title: '最低得分',
        dataIndex: 'minScore',
        key: 'minScore',
    },
    {
        title: '结果排序',
        dataIndex: 'rank',
        key: 'rank',
    },
    {
        title: '问题优化',
        dataIndex: 'questionOptimization',
        key: 'questionOptimization',
    },
  ];



   async function handleSubmit () {

    try{
      const values =await form.validate();
      values.kbId=parseInt(kbId);
    values.topK=parseInt(values.topK);
    console.log('测试values', values);
    setLoading(true);
    instance.post('/api/knowledge/search', values).then(res => {
      console.log(res);
      setTestResults(res.data.data);
      Message.success('测试成功');
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
    }catch(err){
      Message.error('测试失败,请检查输入参数');
    }


    //const values =await form.getFieldsValue();

    
  }


  return (
     <Card>
          <Title heading={5} style={{ }}>搜索测试</Title>
          <span>基于给定的知识库的测试召回效果。</span>
        <Content style={{ padding: '24px', display: 'flex',position: 'relative' }}>
            {loading && (
        <div style={{
          position: 'fixed', // 使用固定定位
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.7)', // 半透明背景
          zIndex: 9999, // 设置较高的层级
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Spin delay={500} loading={loading}></Spin>
        </div>
      )}
          <Card style={{ width: '35%', marginRight: '24px' }}>
          <Title heading={6} style={{ marginTop: '24px' }}>测试参数</Title>
            <div style={{ border: '1px solid #3388e9', borderRadius: '4px', padding: '12px' }}>
            {/* <Space style={{ display: 'flex', justifyContent:'space-between' }} >
            <Select defaultValue="单文本测试" style={{ width: '100%' }}></Select>           
            <Tag>语义检索</Tag>
            </Space> */}
             <Form form={form} initialValues={testParams} title='测试参数' layout="vertical" >
              <Form.Item required label="搜索问题"field={'question'} rules={[{ required: true, message: '请输入搜索问题' }]}>
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 5 }} placeholder="请输入搜索问题"/>
              </Form.Item>
              <Form.Item label="top-K值" field={'topK'} defaultValue={3} rules={[{ required: true, message: '请输入top-K值' }]}>
                <InputNumber required type='number' defaultValue={3}  placeholder="请输入top-K值"min={1} max={10} />
                </Form.Item>
                <Form.Item   label="是否忽略文档状态"field={'ignoreDocStatus'} rules={[{ required: true, message: '请选择是否忽略文档状态' }]} >
                  <Radio.Group options={[{ label: '否', value: false }, { label: '是', value: true }]} />
                </Form.Item>
            </Form>

            
            {/* <TextArea placeholder="输入需要测试的文本" rows={7} title='输入需要测试的文本'
             style={{ border: '0px solid #d9d9d9', borderRadius: '4px', padding: '12px',backgroundColor: '#ffff' }}
             /> */} 
            <Button type="primary" loading={loading} onClick={handleSubmit} icon={<IconSend />} style={{ marginTop: '16px',alignSelf: 'flex-start' }}>测试</Button>
            </div>


            {/* <Title heading={5} style={{ marginTop: '24px' }}>测试历史</Title>
            <List
                dataSource={testHistory}
                render={(item, index) => (
                    <List.Item key={index}>
                        <List.Item.Meta
                            avatar={<Avatar shape='square'>A</Avatar>}
                            title={item.title}
                            description={item.date}
                        />
                    </List.Item>
                )}
            />
            */}
          </Card>

          
          <Card style={{ height: '100%', width: '65%' }} bordered={false}>
            {/* <Title heading={5}>测试参数</Title>
            
            <Table
              data={dataSource}
              columns={columns}
              pagination={false}
              style={{ marginBottom: '24px' }}
            /> */}
            <Title heading={6} style={{ marginTop: '24px' }}>测试结果</Title>

            <div style={{ backgroundColor: 'rgba(242, 243,245,1)'}}>
            <List
              dataSource={
                testResults
              }
              render={(item) => (
                <List.Item key={item.qaId} >
                  <Tag color="blue">得分：{item.score.toFixed(4)}</Tag>
                  <Space direction="vertical">
                   <span>问题: {item.question}</span>
                  </Space>
                  <div>{item.answer}</div>
                </List.Item>
              )}
            />
            </div>
          </Card>
         
        </Content>
    </Card>
  );
};

export default SearchTestPage;

import React from 'react';
import  { useState,useEffect } from 'react';

import { Layout, Menu,Form,Tooltip,Radio,Spin, Table,Popconfirm, Badge,Input, Button, Switch, Dropdown ,Card, Upload, Modal, Space, Link, Message, Avatar, Typography} from '@arco-design/web-react';
import { IconSearch, IconPlus, IconBook, IconQuestionCircle } from '@arco-design/web-react/icon';
//import  { Link as RouterLink } from 'react-router-dom/Link' 错误
import { Link as RouterLink,useLocation, useHistory} from 'react-router-dom';
//import useLocale from '@/utils/useLocale';
import ConfigPage from './config';
import SearchTestPage from './recall-test';
import ApiDocumentation from './apidoc';
import NotesPage from './doc-split';
import instance from '@/api/instance';
import {IKBDetail,DocumentInterface }from './interface'
import { render } from 'react-dom';
import Title from '@arco-design/web-react/es/Typography/title';
// import FileHandle from './file-upload';

 


const { Header, Content, Sider } = Layout;

const  DataPage = () => {


    const [activeKey, setActiveKey] = useState<any>('data');
    const [visible, setVisible] = useState(false);
    const [docDetail, setDocDetail] = useState(false);
    const [data,setData] = useState([] as IKBDetail);
    const [docList, setDocList] = useState([] as DocumentInterface[]);
    const [fileurl, setFileurl] = useState('');
    const [embeddingLoading, setEmbeddingLoading]= useState({});
    
    const [form]=Form.useForm();
    const [formDocEdit]=Form.useForm();

    //更改data中的name
  const handleNameChange = (value) => {
    setData({...data,name:value})
  }

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const kbId = searchParams.get('kbId');
    console.log('kbId',kbId);

    const history = useHistory();
    const navigateToFilePage = () => {
  
      history.push(`/knowledge-base/file-upload/?kbId=${kbId}`);
    };
    const  navigateToJsonFilePage = () => {
      history.push(`/knowledge-base/json-file/?kbId=${kbId}`);
    };
    const  navigateToProQaPage = (id) => {
      history.push(`/knowledge-base/pro-qa/?id=${id}`);
    };

    const handleOk = () => {    
      setVisible(false);
    };

    const handleCancel = () => {
      setVisible(false);  
    }

    const handleMenuClick = (key) => {
        setActiveKey(key);
        console.log('key',key);
        // if (key === '3') {
        //   //history.push('/config'); // 当用户点击“配置”项时导航到配置页面
        //  // console.log('跳转到配置页面');
        // }
      };
    
      const deleteDoc=async(id)=>{
        console.log('id',id);
        instance.post(`/api/knowledge/doc/del/${id}`,{
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((res) => {
          if(res.data.code === '0000'){
            getDocList();
            Message.success('删除成功');
          }else{
            Message.error('删除失败,请重试!');
          }
        })

      }

      const handleFileUpMenuClick = (key) => {
        if (key === 'json') {
          navigateToJsonFilePage();
        }else if(key === 'file'){
          navigateToFilePage();
        }else{
          return;
        }
      }

  const proEmbed = ( docId) => {
    setEmbeddingLoading((prev) => ({ ...prev, [docId]: true }));
    const params = new URLSearchParams();
    //console.log('docId', docId);
    params.append('docID', docId.toString());
    instance.post(`/api/knowledge/doc/generateEmbeddings/${docId}`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(res => {
        console.log('embed res', res);
        Message.success('生成嵌入成功');
        setEmbeddingLoading((prev) => ({ ...prev, [docId]: false }));

        getDocList();
      }).finally(() => {
        setEmbeddingLoading((prev) => ({ ...prev, [docId]: false }));
      });

  }


  const docColumns = [
  //   {
  //     title: '知识库ID',
  //     dataIndex: 'kbId',
  //     key: 'kbId',
  // },
    {
        title: '文档ID',
        dataIndex: 'docId',
        key: 'docId',
        fixed: 'left' as const,
        width:100,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      width:230,
  },
  {
    title: '是否完成问答生成',
    dataIndex: 'hasQa',
    key: 'hasQa',
    width:150,
    render: (hasQa) => {
      if (hasQa) {
        return <Badge status="success" text={'是'}></Badge>;
      }
      return <Badge status="error" text={'否'}></Badge>;
    },
  },
    {
        title: '是否完成嵌入',
        dataIndex: 'hasEmbedding',
        key: 'hasEmbedding',
        width:120,
        render: (x) => {
          if (x) {
            return <Badge status="success" text={'是'}></Badge>;
          }
          return <Badge status="error" text={'否'}></Badge>;
        }
      },
    {
        title: '发布状态',
        dataIndex: 'pubStatus',
        key: 'pubStatus',
        width:100,
        render: (x) => {
          if (x==='Published') {
            return <Badge status="success" text={'已发布'}></Badge>;
          }
          return <Badge status="error" text={'未发布'}></Badge>;
        }
    },
    {
        title: '段落配置',
        dataIndex: 'segmentConfig',
        key: 'segmentConfig',
        width:100,
        render:(x)=> {
          return x.segment=='Words' ? "字符数" : x.segment=='Delimiter' ? "分隔符" : "字段";
        }  
    },
    {
      title: '操作',
      key: 'operations',
      width: 320,
      headerCellStyle: { paddingLeft: '15px' },
      render: (_, row, record) => (
        <Space>
          <Button
            type="text"
            size="small"
           onClick={(e)=>{e.stopPropagation();editDoc(row)}}
          >
            {'详情'}
          </Button>
          <Button
            type="text"
            size="small"
            disabled={row.hasQa}
           onClick={(e)=>{e.stopPropagation();navigateToProQaPage(row.docId)}}
          >
            {'问答生成'}
          </Button>
          <Button
            type="text"
            size="small"
           // loading={embeddingLoading}
           loading={embeddingLoading[row.docId] || false}
            disabled={row.hasEmbedding || !row.hasQa}
            onClick={(e)=>{e.stopPropagation();proEmbed(row.docId)}}
          >
            {'生成嵌入'}
          </Button>
          <Popconfirm
            focusLock
            title="确认删除文档吗？"
            okText="删除"
            cancelText="取消"
            onOk={(e)=>{e.stopPropagation();deleteDoc(row.docId)}}
            onCancel={(e) => {
              e.stopPropagation();
              Message.error({
                content: '取消删除',
                duration: 1500,
              });
            }}
          >
            <Button type="text" onClick={(e) => e.stopPropagation()} size="small">
            {'删除'}
            </Button>
          </Popconfirm>
          </Space>
          )
}
];


  useEffect(() => {
    getKnowlegeBaseData();
  }, []);

  //上传文件后刷新数据
  useEffect(() => {
    getDocList();
  },[]);

 

const editDoc=async(row)=>{
  console.log('row',row);
  formDocEdit.setFieldsValue(row);
  setDocDetail(true);
}


const editDocSubmit = async () => {

  try{
    const values= await formDocEdit.validate();
    console.log('doc edit values',values);
   instance.post('/api/knowledge/doc/update',values,{
   }).then((res) => {
    Message.success('编辑成功');
    setDocDetail(false);
    //formDocEdit.resetFields();
     getDocList();
   })
  }catch(err)  {
    Message.error('请检查输入!');
  }
}

  const getKnowlegeBaseData = () => {
    // TODO: 获取知识库数据
    instance.get(`/api/knowledge/${kbId}`).then((res) => {
      console.log('res',res);
      //setDataSource(res.data);
      setData(res.data.data);
    }).catch((err) => {
   Message.error(err.message);
    
  })
};


const getDocList = () => {
  // TODO: 获取知识库数据
  instance.get(`/api/knowledge/list/docs/${kbId}`).then((res) => {
    console.log('res',res);
    //setDataSource(res.data);
    console.log('docList',res);
    setDocList(res.data.data);
  }).catch((err) => {
 Message.error(err.message);
  
})
};

const getFormItem=()=>{
  return (
  <Form form={form} >
     <Form.Item label="上传文件">
     <Upload 
            drag
            multiple
            accept='*'
            action='/'
            onDrop={(e) => {
              // let uploadFile = e.dataTransfer.files[0]
              // if (isAcceptFile(uploadFile, 'image/*')) {
              //   return
              // } else {
              //   Message.info('不接受的文件类型，请重新上传指定文件类型~');
              // }
            }}
            onChange={(fileList) => {
              // 处理上传文件的逻辑
              if (fileList.length > 0) {
                //setDefaulList(fileList);
                //console.log('公会图标fileList[0]', fileList[0]);
                //if (fileList[0].response) {
                // // console.log('公会图标iconUrl', fileList[0].response.data?.url);
                //  //form.setFieldsValue({
                //  //  iconUrl: fileList[0].response.data.url,
                //  //});
                //  if(fileList[0].response){
                //  setFileurl(fileList[0].response.data?.url);
                //  }
    
                //}
              }
            }}
           // tip='Only pictures can be uploaded'
            >
          </Upload>
            </Form.Item>
  <Form.Item label="回答字段" field="answer_field">
                <Input placeholder="输入回答字段" />
            </Form.Item>
            <Form.Item label="分隔符" field="delimiter">
                <Input placeholder="输入分隔符" />
            </Form.Item>
            <Form.Item label="是否修剪" field="isTrim">
                <Switch />
            </Form.Item>
            <Form.Item label="重叠数" field="overlaps">
                <Input type="number" placeholder="输入重叠数" />
            </Form.Item>
            <Form.Item label="问题字段" field="question_field">
                <Input placeholder="输入问题字段" />
            </Form.Item>
            <Form.Item label="段落" field="segment">
                <Input placeholder="输入段落" />
            </Form.Item>
            <Form.Item label="单词数" field="words">
                <Input type="number" placeholder="输入单词数" />
            </Form.Item>
            </Form>
  )
          
};



  const menu = (
    <Menu onClickMenuItem={ handleFileUpMenuClick}>
      <Menu.Item key="json">json文件</Menu.Item>
      <Menu.Item key="file">非json文件</Menu.Item>
    </Menu>
  );

  return (
    //<Card style={{ height: '100%',display: 'flex', flexDirection: 'column' }}>
    <Layout style={{ height: '100%' }}>
      <Sider >
       <Space style={{marginTop: 20,marginLeft: 20,marginRight:"auto",fontSize: 20, borderRadius: "5%" ,marginBottom: 30,textAlign: "center"}}>
          <Avatar shape='square'> <IconBook /></Avatar>
          <Title  heading={5} style={{textAlign: "center"}}> {data?.name}</Title>
        </Space>
        <Menu  onClickMenuItem={ handleMenuClick}>
          <Menu.Item key="data">数据集</Menu.Item>
          <Menu.Item key="test">搜索测试</Menu.Item>
          <Menu.Item key="config">配置</Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ height: '100%'}}>
      {activeKey !== 'config' && activeKey !=='test' &&  activeKey !=='data' && activeKey !=='uploadFile'  && <NotesPage record={activeKey} />}  
      {/* {activeKey === 'uploadFile' && <FileHandle/>}   */}
      {activeKey === '4' && < ApiDocumentation id='4' />}
      {activeKey === 'config' && <ConfigPage id={kbId}  editData={handleNameChange} />}
      {activeKey === 'test' && <SearchTestPage kbId={kbId} />}
      {activeKey === 'data' && 
      <Card>
        <Header>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{marginLeft: 20,marginTop: 30}}>
            <Title heading={5}>文档列表</Title>
            <span >这里展示上传的文档列表</span>
            </div>
            <div>
              {/* <Input style={{ width: 200, marginRight: 10 }} prefix={<IconSearch />} placeholder="搜索" /> */}
              <Dropdown trigger="click" droplist={menu}  >
                <Button type="secondary" shape="round" style={{borderRadius: "6%",marginRight: 20}} icon={<IconPlus />}>上传文件</Button>
              </Dropdown>
              {/*<Space style={{ marginLeft: 20,marginRight: 20 }}>*/}
              {/* <Button type="secondary" icon={<IconPlus/>}  onClick={() => {setVisible(true)}}>上传文件</Button> */}
              {/*<Button type="secondary" icon={<IconPlus/>} style={{borderRadius: "6%" }}  onClick={navigateToFilePage}>上传文件</Button>*/}
              {/* <Link href={`/api-doc/${activeKey}`}>api文档</Link>
              <RouterLink to={`/api-doc/${activeKey}`}>api文档2</RouterLink> */}
              {/*</Space>*/}
            </div>
          </div>
        </Header>
        {/* <Modal
          visible={visible} 
          onOk={handleOk}
          onCancel={handleCancel} 
          //

        >
         {getFormItem()}

          TODO:上传文件重置数据
         <Upload 
            drag
            multiple
            accept='*'
            action='/'
            onDrop={(e) => {
              // let uploadFile = e.dataTransfer.files[0]
              // if (isAcceptFile(uploadFile, 'image/*')) {
              //   return
              // } else {
              //   Message.info('不接受的文件类型，请重新上传指定文件类型~');
              // }
            }}
           // tip='Only pictures can be uploaded'
            >
          </Upload>
        </Modal> */}

        <Content style={{ minHeight: '100vh', padding: '24px' }}>
          
          <Table
           // rowSelection={{ type: 'checkbox' }}
           // data={docList}
            data={docList.map(item => ({
              key: item.docId, // 为每个行提供唯一的 key 属性,否则浏览器会报错warning
              ...item,
            }))}
            scroll={{ x: 1200, y: 1000 }}
            columns={docColumns}
            pagination={false}
            onRow={ (record) => {
              return {
                onClick: () => {
                  // history.push(`/data/${record.key}`); // 当用户点击某一行时导航到详情页面
                  // console.log('跳转到详情页面', record.key);
                 // setDocDetail(true);
                if(record.hasQa){
                  setActiveKey(record);
                }else{
                  Message.info('该文档没有生成问答对，请先生成问答对！');
                }
              }
            }}
          }
          /> 
          <Modal
            visible={docDetail}
            title="文档详情"
            onOk={editDocSubmit}
            onCancel={() => {setDocDetail(false);}}
            okText="保存编辑"
          >
           <Form form={formDocEdit}  labelCol={{ span: 7 }} wrapperCol={{ span: 14 }}  style={{ margin: '60 30 0 0 ', display: 'flex', justifyContent: 'flex-start', }}  >
              <Form.Item  required label="文档ID" field={'docId'}>
                <Input type='text' disabled />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: '请输入文档名称' }]} label="文档名称" field={'name'}>
                <Input placeholder="请输入文档名称" />
              </Form.Item>
              <Form.Item   label="发布状态" field={'pubStatus'}rules={[{ required: true, message: '请选择发布状态' }]} >
              <Radio.Group   defaultValue={'Words'}style={{ display: 'flex', justifyContent: 'flex-start' }} >
                  <Radio value='Waiting'>未发布</Radio>
                  <Radio value='Published'>已发布</Radio>
                </Radio.Group>
              </Form.Item>
             
              <Form.Item disabled label={<span  style={{  width: '120px', whiteSpace: 'normal' }}>
                <span>选择分段的类型</span>
                <Tooltip color="#434950" content="若上传的文件为json格式，则选择问答对类型，其他类型文件则选择字符数或分隔符类型。">
                 <IconQuestionCircle/>
                </Tooltip>
                </span>}
                field="segmentConfig.segment" style={{ textAlign: 'left' }}>
                <Radio.Group type='button' defaultValue={'Words'}style={{ display: 'flex', justifyContent: 'flex-start' }} >
                  <Radio value='Words'>字符数</Radio>
                  <Radio value='Delimiter'> 分隔符</Radio>
                  <Radio value='Field'> 问答对</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item style={{ display: 'flex', alignItems: 'center' }} shouldUpdate noStyle  >
                {(values) => {
                  if (values.segmentConfig.segment === 'Field') {
                    return (
                      <>
                        <Form.Item disabled rules={[{ required: true, message: '请选择问题字段' }]} label="问题字段" field="segmentConfig.question_field">
                          <Input placeholder="请输入json文件中的“问题”字段" />
                        </Form.Item>
                        <Form.Item  disabled rules={[{ required: true, message: '请选择答案字段' }]} shouldUpdate label="答案字段" field="segmentConfig.answer_field">
                          <Input  placeholder="请输入json文件中的“答案”字段"/>
                        </Form.Item></>
                    )
                  }
                  else if (values.segmentConfig.segment === 'Delimiter') {
                    return (
                      <>
                        <Form.Item
                          label={<span style={{ width: '90px', whiteSpace: 'normal' }}>是否去除连续空白、制表符和空行</span>}
                          field="segmentConfig.isTrim" style={{ textAlign: 'left' }}>
                          <Radio.Group disabled style={{ display: 'flex', justifyContent: 'flex-start' }} >
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item disabled rules={[{ required: true, message: '请输入分隔符' }]} label="分隔符" field="segmentConfig.delimiter">
                          <Input placeholder="输入分隔符" />
                        </Form.Item>
                        <Form.Item required disabled label="分段最大字符数" field="segmentConfig.words">
                          <Input type="number" placeholder="输入分段最大字符数" />
                        </Form.Item>
                        <Form.Item required disabled label="重叠字符数" field="segmentConfig.overlaps">
                          <Input type="number" placeholder="输入分段重叠字符数" />
                        </Form.Item>

                      </>)

                  } else {
                    return (
                      <>
                        <Form.Item
                          label={<span style={{ width: '90px', whiteSpace: 'normal' }}>是否去除连续空白、制表符和空行</span>}
                          field="segmentConfig.isTrim" style={{ textAlign: 'left' }}>
                          <Radio.Group disabled style={{ display: 'flex', justifyContent: 'flex-start' }} >
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item required disabled style={{ display: 'flex', alignItems: 'center' }} label="分段最大字符数" field="segmentConfig.words">
                          <Input type="number" placeholder="输入分段最大字符数" />
                        </Form.Item>
                        <Form.Item required disabled label="重叠字符数" field="segmentConfig.overlaps">
                          <Input type="number" placeholder="输入分段重叠字符数" />
                        </Form.Item>
                      </>
                    )
                  }
                }
                }
              </Form.Item>
            </Form>
            </Modal>
        </Content>
        </Card>
        }
      </Layout>
    </Layout>
    //</Card>
  );
};

export default DataPage;
